@import "./src/assets/scss/main.scss";













.navbar-vertical {
    width: 260px;

    &__btn {
        @extend .btn;
        width: 100%;
        padding-top: 22px;
        padding-bottom: 23px;
        margin-bottom: 10px;
        background: #EBEBEB;
        border-radius: 12px;
        font-family: Montserrat;

        font-weight: 500;
        font-size: 16px;
        line-height: 109.5%;
        /* identical to box height, or 18px */

        color: #636363;

        &--white {
            background: #fff;
        }
        &:hover{
            background: $orange;
            color: #fff;
        }
    }
}
.router-link-exact-active {
    background: $orange;
    color: #fff;
}
